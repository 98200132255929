import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VERSION } from '../../../environments/version';

import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'cube-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit, OnDestroy {
  isDarkTheme: boolean;

  version = VERSION;

  @Input() menus: any[] = [];
  @Input() iconOnly = false;

  private subscriptions: Subscription[] = [];

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.subscriptions.push(this.themeService.isDarkTheme.subscribe((isDark) => (this.isDarkTheme = isDark)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
