import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'cube-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() sidenav;
  @Input() sidebar;
  @Input() drawer;
  @Input() matDrawerShow;

  currentUser?: User = null;

  searchOpen = false;
  isSupportToolEnabled = true;
  isSettingPanelEnabled = false;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.userService.isLoggedIn() ? (this.currentUser = this.userService.getUser()) : null;
  }

  ngOnDestroy(): void {}

  onAction(action: any) {
    // console.log(action);
  }

  onSearch(search: any) {}

  openSupportPage() {
    window.open(environment.supportUrl, '_blank');
  }
}
