import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { FilterPipe } from './filter/filter.pipe';

@NgModule({
  imports: [],
  declarations: [SafeHtmlPipe, FilterPipe],
  exports: [SafeHtmlPipe, FilterPipe],
})
export class PipesModule {}
