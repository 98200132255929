import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';

import { User } from '../../models/user.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: User = null;
  private logoutRedirect: string = window.location.origin;
  private profileRedirect = environment.keycloak.url + '/realms/' + environment.keycloak.realm + '/account/';

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {}

  public isLoggedIn(): boolean {
    return this.user != null;
  }

  public getUser(): User {
    return this.user;
  }

  public setUser(user: User): void {
    this.user = user;
  }

  public updateToken(token: string): void {
    this.user.token = token;
  }

  public doLogout(redirect?: string): void {
    this.user = null;
    this.keycloakService.logout(redirect || this.logoutRedirect);
  }

  public doLogin(): void {
    this.keycloakService.login();
  }

  public goToProfile() {
    window.location.href = this.profileRedirect;
  }
}
