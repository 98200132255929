import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';

import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'cube-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  isOpen = false;

  @Input() currentUser: User = null;

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  constructor(
    private elementRef: ElementRef,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  doLogout() {
    this.googleAnalyticsService.trackEvent('security', 'logout: ' + this.currentUser.id);
    this.userService.doLogout();
  }

  doLogin() {
    this.userService.doLogin();
  }

  goProfile() {
    this.userService.goToProfile();
  }
}
