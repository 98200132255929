import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { User } from './models/user.model';
import { Keycloak } from 'keycloak-angular/lib/core/services/keycloak.service';
import { UserService } from './services/user/user.service';

// https://medium.com/@gmurop/managing-dependencies-among-app-initializers-in-angular-652be4afce6f
// https://www.intertech.com/Blog/angular-4-tutorial-run-code-during-app-initialization/

export function authInitializer(keycloakService: KeycloakService, userService: UserService): () => Promise<any> {
  return (): Promise<any> => initKC(keycloakService, userService);
}

function initKC(keycloakService: KeycloakService, userService: UserService): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      keycloakService
        .init({
          config: environment.keycloak,
          loadUserProfileAtStartUp: false,
          initOptions: {
            onLoad: 'check-sso', // login-required || check-sso
            checkLoginIframe: false,
          },
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: environment.auth.bearerExcludedUrls,
        })
        .then((success: boolean) => {
          if (success) {
            keycloakService
              .loadUserProfile()
              .then((userProfile: Keycloak.KeycloakProfile) => {
                const u = userProfile as User;
                keycloakService
                  .getToken()
                  .then((token) => {
                    u.token = token;
                    u.id = keycloakService.getKeycloakInstance().idTokenParsed.sub;
                    userService.setUser(u as User);
                    localStorage.setItem('token', token);
                    resolve(true);
                  })
                  .catch((error) => {
                    console.error(error);
                    reject(error);
                  });
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
