import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ThemeService } from './services/theme/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';

declare let ga: Function;

@Component({
  selector: 'cube-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isDarkTheme: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    translate: TranslateService,
    private overlayContainer: OverlayContainer,
    private themeService: ThemeService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'en');
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.themeService.isDarkTheme.subscribe((isDark) => {
        this.isDarkTheme = isDark;
        this.overlayContainer.getContainerElement().classList.remove(isDark ? 'light-theme' : 'dark-theme');
        this.overlayContainer.getContainerElement().classList.add(!isDark ? 'light-theme' : 'dark-theme');
      })
    );
    this.googleAnalyticsService.subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.googleAnalyticsService.unsubscribe();
  }
}
