// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { NgxLoggerLevel } from 'ngx-logger';
import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://login.cube.rina.org/auth',
  realm: 'cube',
  clientId: 'dewa',
};

const companyConfig: any = {
  logo: './assets/logo.png',
  logoBg: 'bg13294b',
  name: 'DEWA',
  role: '*',
};

export const environment = {
  name: 'RINA',
  production: false,
  keycloak: keycloakConfig,
  company: companyConfig,
  auth: {
    refreshing_time: 5000,
    token_lifespan: 60,
    bearerExcludedUrls: ['https://dewa-grafana.azurewebsites.net/*'],
  },
  logger: {
    serverLoggingUrl: 'http://localhost:3000/logs',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.ERROR,
  },
  render_delay: 400,
  refreshing_time: '10s',
  warn_timeout: 5000,
  error_timeout: 10000,
  snackbar_duration: 1500,
  snackbar_error_duration: 3000,
  date_format: 'MM-dd-yyyy HH:mm',
  supportUrl: 'https://support.cube.rina.org',
  google: {
    analytics: {
      active: true,
      uaId: 'UA-128968550-2',
      logging: {
        debug: false,
        exceptions: true,
        verbose: false,
      },
    },
  },
};
